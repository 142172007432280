var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"full-height fill-width background",attrs:{"fluid":""}},[_c('v-row',{staticClass:"fill-height d-flex justify-center align-center background"},[_c('v-col',{staticClass:"fill-width d-flex justify-center align-center",attrs:{"cols":"12","sm":"12","md":"6"}},[(!_vm.showTwoFactorDialog)?_c('login-component',{attrs:{"onPasswordResetPressed":_vm.onPasswordResetPressed,"onGoogleLoginCompleted":_vm.onGoogleLogin,"onAppleLoginPressed":_vm.onAppleLogin,"onLoginPressed":_vm.doLogin}}):_vm._e(),(_vm.showTwoFactorDialog)?_c('two-factor-login-component',{attrs:{"onLoginPressed":_vm.onCodeEntered,"onBackPressed":_vm.onBackPressed}}):_vm._e()],1),_c('v-col',{staticClass:"background fill-width",class:{'pa-0': !_vm.isMobile},attrs:{"cols":"12","sm":"12","md":"6"}},[_c('div',{staticClass:"green-column dark-green fill-width d-flex",class:{
              'mobile-partner-container': _vm.isMobile,
              'full-height': !_vm.isMobile,
              'justify-end': !_vm.isMobile,
              'flex-column': !_vm.isMobile,
              'align-center': !_vm.isMobile,
              'justify-space-between': _vm.isMobile,
              'mobile-wrapper': _vm.isMobile
           }},[(!_vm.isMobile)?_c('div',{staticClass:"fill-height fill-width d-flex justify-center align-center"},[_c('v-img',{attrs:{"src":require('../../assets/login_image.png'),"max-width":"25vw"}}),_c('v-img',{staticClass:"fill-width leaves-absolute",attrs:{"src":require('../../assets/login_svgs_big.svg'),"min-width":"25vw","max-height":"100vh"}})],1):_vm._e(),_c('v-row',{staticClass:"d-flex justify-center fill-width"},[_c('v-col',{staticClass:"d-flex align-end pb-8 justify-center",attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"text-wrapper d-flex flex-column justify-center",class:{
                'pa-16': _vm.isMobile,
                'align-center': !_vm.isMobile,
                'additional-text-padding': !_vm.isMobile,
                'mobile-text-width': _vm.isMobile,
                'unMobile-text-width': !_vm.isMobile
            }},[_c('h2',{staticClass:"white--text mb-4"},[_vm._v(_vm._s(_vm.$t('LOGIN.BECOME_PARTNER.TITLE')))]),_c('span',{staticClass:"white--text mb-8",class:{'text-center': !_vm.isMobile}},[_vm._v(_vm._s(_vm.$t('LOGIN.BECOME_PARTNER.DESCRIPTION')))]),_c('v-btn',{staticClass:"primary--text",attrs:{"to":"/register","outlined":"","rounded":""}},[_vm._v(_vm._s(_vm.$t('LOGIN.BECOME_PARTNER.BUTTON')))])],1)]),(_vm.isSmall)?_c('v-col',{staticClass:"d-flex justify-end align-end",attrs:{"cols":"12","sm":"6"}},[_c('v-img',{staticClass:"mobile-absolute",attrs:{"src":require('../../assets/login_image.png'),"max-width":"25vw"}}),_c('v-img',{attrs:{"src":require('../../assets/login_svgs_small.svg')}})],1):_vm._e()],1)],1)])],1),_c('v-dialog',{attrs:{"fullscreen":"","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.showPasswordDialog = !_vm.showPasswordDialog}},model:{value:(_vm.showPasswordDialog),callback:function ($$v) {_vm.showPasswordDialog=$$v},expression:"showPasswordDialog"}},[_c('password-request-component',{attrs:{"email":_vm.email},on:{"close":function($event){_vm.showPasswordDialog = !_vm.showPasswordDialog}}})],1),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.showVerificationDialog = !_vm.showVerificationDialog}},model:{value:(_vm.showVerificationDialog),callback:function ($$v) {_vm.showVerificationDialog=$$v},expression:"showVerificationDialog"}},[(_vm.showVerificationDialog)?_c('resend-verification-component',{attrs:{"email":_vm.verificationEmail},on:{"close":function($event){_vm.showVerificationDialog = !_vm.showVerificationDialog}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }