
import {namespace} from 'vuex-class';
import {Component} from 'vue-property-decorator';
import {AUTH_STORE_NAME, AuthStoreActions} from '@/store/auth.store';
import {ROUTE_COMPANY, ROUTE_HOME} from '@/router/routes';
import {required} from 'vuelidate/lib/validators';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import {validationMixin} from 'vuelidate';
import {mixins} from 'vue-class-component';
import Restauranteur from '@/models/Restaurateur';
import {AccountType} from "@/enum/AccountType.enum";

// @ts-ignore
import {decode, sign, verify} from 'jsonwebtoken';


const AuthStore = namespace(AUTH_STORE_NAME);

@Component({
  components: {
    PasswordRequestComponent: () => import(
      /* webpackChunkName: "PasswordRequestComponent" */
      '@/components/Authentication/PasswordRequest.component.vue'
    ),
    ResendVerificationComponent: () => import(
      /* webpackChunkName: "ResendVerificationComponent" */
      '@/components/Authentication/ResendVerification.component.vue'
    ),
    LoginComponent: () => import(
      /* webpackChunkName: "LoginComponent" */
      '@/components/Authentication/Login.component.vue'
    ),
    TwoFactorLoginComponent: () => import(
      /* webpackChunkName: "TwoFactorLoginComponent" */
      '@/components/Authentication/TwoFactorLogin.component.vue'
    ),
  },
  mixins: [validationMixin],
  validations: {
    username: { required },
    password: { required }
  }
})
export default class LoginView extends mixins(ErrorMessageHandlerMixin) {
  @AuthStore.Action(AuthStoreActions.LOGIN)
  private loginAction!: (payload: { username: string, password: string, code?: string }) => Promise< Restauranteur | null>;

  @AuthStore.Action(AuthStoreActions.GOOGLE_LOGIN)
  private googleLoginAction!: (payload: {idToken: string}) => Promise<Restauranteur | null>;

  @AuthStore.Action(AuthStoreActions.APPLE_LOGIN)
  private appleLoginAction!: (payload: {idToken: string}) => Promise<Restauranteur | null>;

  private email: string = '';
  private showPasswordDialog: boolean = false;
  private showVerificationDialog: boolean = false;
  private showTwoFactorDialog = false;
  private $gAuth: any;

  private username = '';
  private password = '';

  private verificationEmail: string = '';

  /**
   * redirects the user to the home view if he/she is logged in
   * @private
   */
  private created() {
    // login provides redirect functionality, routing settings are showing here to make redirecting work
    // https://stackoverflow.com/a/65056332
    this.searchParams = new URLSearchParams(window.location.search);
    if(this.searchParams?.has("redirect")) this.showAlert = true;

    // Redirect to home if already logged in:
    if (this.$isLoggedIn()) {
      this.$router.push({ name: ROUTE_HOME });
    }
  }

  private onBackPressed() {
    this.showTwoFactorDialog = false;
  }

  /**
   * tries to handle google login
   * @private
   */
  private async onGoogleLogin(idToken?: string) {
    if(!idToken) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GOOGLE_LOGIN_FAILED');
      return;
    }

    const decoded = decode(idToken) as any;
    this.verificationEmail = decoded.email.toString();

    try {
      // logs in the user, checks if the user has the correct role
      const user = await this.googleLoginAction({idToken});
      await this.handleLogin(user);
    } catch(e) {
      this.handleError(e);
    }
  }

  /**
   * is called when using the apple login, gets the auth code from apple and tries to login the user
   * @param result from the apple button popup (contains the token etc.)
   * @private
   */
  private async onAppleLogin(result: any) {
    try {
      this.verificationEmail = result.userData.email;
      const user = await this.appleLoginAction({idToken: result.authorization.code});
      await this.handleLogin(user);
    } catch(e) {
      if(this.handleSSOError(e)) return;
      this.handleError(e);
    }
  }

  private handleSSOError(error: any): boolean {
    this.$handleError(error, () => {
      switch (error.status) {
        case 409:
          this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.SSO_MAIL_ALREADY_USED', null, 5000);
          return true;
      }
    });

    return false;
  }

  /**
   * checks if the view is showing small or extra small breakpoint
   */
  public get isMobile() {
    return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
  }

  /**
   * checks if the view is showing small breakpoint
   */
  public get isSmall() {
    return this.$vuetify.breakpoint.sm;
  }

  /**
   * State bool to show the Alert above the Form
   * @private
   */
  private showAlert: boolean = false;

  /**
   * search params of the view e.g. redirect link
   * @private
   */
  private searchParams?: URLSearchParams;

  /**
   * is called when user taps on login button, tries to login the user
   * @private
   */
  private async doLogin(payload: {username: string, password: string}) {
    this.username = payload.username;
    this.password = payload.password;
    this.email = payload.username;

    try {
      // logs in the user, checks if the user has the correct role
      const user = await this.loginAction({username: payload.username, password: payload.password});
      await this.handleLogin(user);
    } catch(e) {
      this.handleError(e);
    }
  }

  /**
   * is called after a login way (normal login / sso), receives the user and tries to handle rest
   * @private
   */
  private async handleLogin(user: Restauranteur | null) {
    if(!user) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.UNAUTHORIZED_ROLE');
      return;
    }

    // checks if there is a redirect path and navigates to the redirect url
    if(this.searchParams?.has("redirect")) {
      return await this.$router.push({name: `${this.searchParams!.get("redirect")}`});
    }

    // if user is pb, go to company overview
    if(user.account!.role === AccountType.PB) {
      // pushes the application to the home view
      return await this.$router.push({ name: ROUTE_HOME });
    }

    // if user is gn, go to specific company view with the id of the company of the user
    if(user.account!.role === AccountType.GN) {
      // pushes the application to the home view
      return await this.$router.push({ name: ROUTE_COMPANY, params: {id: user.company!.id!}});
    }
  }

  private openTwoFactor() {
    this.showTwoFactorDialog = true;
  }

  private onCloseTwoFactorDialog() {
    this.showTwoFactorDialog = false;
  }

  private onPasswordResetPressed() {
    this.showPasswordDialog = true;
  }

  private async onCodeEntered(value: string) {
    try {
      // logs in the user, checks if the user has the correct role
      const user = await this.loginAction({username: this.username, password: this.password, code: value});
      this.showTwoFactorDialog = false;
      await this.handleLogin(user);
    } catch(e) {
      this.handleError(e);
    }
  }

  /**
   * handles the error
   * @param error++
   * @private
   */
  private handleError(error: any) {
    this.$handleError(error, () => {
      switch (error.status) {
        case 400:
          this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.LOGIN_WRONG_CREDENTIALS');
          break;
        case 401:
          this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.LOGIN_USER_NOT_EXIST');
          break;
        case 403: // User not verified or not active
          this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.LOGIN_USER_NOT_VERIFIED_OR_ACTIVE');
          break;
        case 405:
          this.verificationEmail = this.email;
          this.showVerificationDialog = true;
          return;
        case 424:
          this.openTwoFactor();
          break;
        case 409:
          this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.CHANGE_EMAIL_ALREADY_EXISTS');
          break;
        case 412:
          this.showVerificationDialog = true;
          break;
        case 422: // E-Mail not registered or wrong e-mail/password
          this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.WRONG_TWO_FACTOR_CODE');
          break;
        default:
          this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.LOGIN_ERROR');
      }
    });
  }
}
